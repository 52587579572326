* {
	box-sizing: border-box;
	outline: 0;
	-webkit-tap-highlight-color: rgba(0, 0, 0, 0);
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;

	font-family: $font-family;
	font-weight: normal;
	z-index: 1;
}

html,
body {
	@extend %fullscreen;
	background-color: $cBg;
	font-family: $font-family;
	line-height: $line-height;
	color: $cPrimary;
	font-size: $font-size;

	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;

	@include respond-to(desktop) {
		font-size: 26px;
	}
}

section,
main {
	@extend %cf;
	width: 100%;
	height: 100%;
	padding: $page-padding;
	z-index: 4;
}

.wrap {
	width: 100%;
	height: 100%;
	position: relative;
	z-index: 5;
	@include respond-to(tablet-portrait) {
	}
	@include respond-to(desktop) {
	}
}

#root {
	@extend %fullscreen;
}

.app-wrap {
	@extend %fullscreen;

	> div {
		display: grid;
		grid-template-areas: 'app';
		grid-template-columns: 100% 100%;
		min-height: 100vh;
		width: 100%;
		margin: auto;
		position: relative;

		> div,
		> div > div {
			backface-visibility: hidden;
			height: 100%;
			grid-area: app;
		}
	}

	@include respond-to(tablet-portrait) {
		> div {
			overflow: hidden;
		}
	}
}

.container {
	padding-top: $content-top-padding;

	> .logo {
		position: absolute;
		top: 18px;
		left: $page-padding;
	}
}

.fwi {
	@extend %cf;
	@extend %fullwidth;
}

.page {
	background-color: $cBg;
	z-index: 11;
	position: relative;
	top: 0px;
	left: 0px;

	/* 
    position: absolute; 
    */
	margin: auto;
	width: 100%;
	height: 100%;

	// transform: translateX(0);
	//transition: all 500ms;

	transition: left 300ms ease-out, opacity 300ms ease-out;
	//transition-delay: 0.2s;

	&.modal-page {
		background-color: $cw;
	}

	@include respond-to(tablet-portrait) {
	}

	@include respond-to(desktop) {
	}
}

img {
	max-width: 100%;
	height: auto;
	vertical-align: bottom;
	&.fw {
		width: 100%;
	}
}

figure {
	width: 100%;
	img {
		width: 100%;
	}
}

.not-found {
	h1 {
		display: none;
	}
}
