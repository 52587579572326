#audio-player {
	display: none;
}

.audio-controls {
	position: absolute;
	bottom: $page-padding;
	left: $page-padding-large;
}

.seekbar {
	cursor: pointer;
	height: 8px;
	margin: 50px 0px 0px;
	background: rgba(247, 247, 239, 0.5);
	position: relative;

	.bg {
		background: #f7f7ef;
		position: absolute;
		top: 0;
		left: 0;
		height: 8px;
		width: 0px;
		transition: width 0.2s ease-in;

		&:after {
		}
	}

	.handle {
		cursor: move;
		position: absolute;
		top: -4px;
		right: 0px;
		width: 17px;
		height: 17px;
		background: #f7f7ef;
		border-radius: 50% 50%;
	}
}

.audio-info {
	color: $cRed;
	padding: 0px $page-padding;
	transition: opacity 0.2s ease-in;
	opacity: 0;
	pointer-events: none;
	overflow: hidden;
	height: 0;

	p {
		display: block;
		padding-top: 10px;
	}

	&.visible {
		padding: 10px $page-padding;
		opacity: 1;
		pointer-events: all;
		height: auto;
	}
}
