%modal {
	position: fixed;
	top: 0px;
	left: 0;
	overflow: hidden;
	background-color: rgba(0, 0, 0, 0.6);
	opacity: 0;
	pointer-events: none;
	z-index: 999;
	width: 100vw;
	height: 100vh;

	transition: opacity 0.2s ease-in-out;

	.content {
		bottom: -100vh;
		height: 100vh;
		position: absolute;
		left: 50%;
		width: 100%;
		transform: translate(-50%, 0%);
		transition: opacity 0.1s ease-in-out, bottom 0.2s ease-in-out;
		overflow: auto;
		padding: $page-padding;
	}

	.inner {
		@extend %centered;
		width: 40%;
		text-align: center;

		.btn {
			margin-bottom: 10px;
		}
	}

	&.visible {
		pointer-events: auto;
		opacity: 1;
		z-index: 999;

		.content {
			bottom: 0px;
		}
	}
}
