.read-more-container {
	width: 100%;
	clear: both;
	margin-bottom: 20px;
}

.more {
	clear: both;
	overflow: hidden;
	transition: opacity 0.3s ease-in-out, max-height 0.3s ease-in-out;
	max-height: 3 * $line-height;

	&.visible {
		max-height: 1200px;
		opacity: 1;
	}

	> div {
		@extend %text-content;
	}
}
