%close {
	display: block;
	position: absolute;
	width: 25px;
	height: 25px;
	right: 22px;
	top: 21px;
	background: url('../../images/close.svg') no-repeat center center
		transparent;
	background-size: 16px 16px;
	cursor: pointer;
	border: none !important;
	transition: all 0.2s ease-in;
}

@keyframes effect_dylan {
	50% {
		transform: scale(1.5, 1.5);
		opacity: 0;
	}
	99% {
		transform: scale(0.001, 0.001);
		opacity: 0;
	}
	100% {
		transform: scale(0.001, 0.001);
		opacity: 1;
	}
}

@mixin focus($color) {
	position: relative;
	overflow: hidden;

	&:after {
		content: '';
		background-color: $color;
		display: block;
		position: absolute;
		border-radius: 50%;
		z-index: 0;
		top: 0;
		right: 0;
		bottom: 0;
		left: 0;
		transform: scale(0.001, 0.001);
	}

	&.clicked {
		outline: 0;
		&:after {
			animation: effect_dylan 0.3s ease-out;
		}
	}
}

%overlay-icon {
	position: absolute;
	opacity: 0.5;
	transition: opacity 0.4s ease-in;
	width: 80px;
	height: 80px;
	left: 50%;
	top: 50%;
	margin-left: -40px;
	margin-top: -40px;
	border-width: 0;
	background-color: transparent !important;
	font-size: 80px;
	line-height: 1;

	&:hover {
		opacity: 1;
	}
}

.legal {
	font-size: 14px;
	padding-top: 10px;
}

:root {
}

.visited-progress {
	--perc: 0;
	--sw: 4;
	--atime: 2s;
	--pi2: 6.28318;
	--r: 30;
	--perim: calc(var(--pi2) * var(--r));
	--pend: calc(var(--r) * var(--pi2) * var(--perc) / 100);
	--prest: calc(var(--r) * var(--pi2) * (100 - var(--perc)) / 100);
}

.audio-progress {
	--sw: 4;
	--atime: 2s;
	--pi2: 6.28318;
	--r: 30;
	--aperc: 0;
	--apend: calc(var(--r) * var(--pi2) * var(--aperc) / 100);
	--aprest: calc(var(--r) * var(--pi2) * (100 - var(--aperc)) / 100);
}

.video-progress {
	--sw: 4;
	--atime: 2s;
	--pi2: 6.28318;
	--r: 30;
	--vperc: 0;
	--vpend: calc(var(--r) * var(--pi2) * var(--vperc) / 100);
	--vprest: calc(var(--r) * var(--pi2) * (100 - var(--vperc)) / 100);
}

#progress {
	stroke: #838383; /* #428bca; */
	stroke-width: var(--sw);
}
#pbar {
	stroke-width: var(--sw);
	fill: none;
	stroke: #838383;
	stroke-dasharray: var(--pend) var(--prest);
	transition: stroke-dasharray 0s ease-out;
}

#audio-progress {
	stroke: #838383; /* #428bca; */
	stroke-width: var(--sw);
}
#audio-pbar {
	stroke-width: var(--sw);
	fill: none;
	stroke: #838383;
	stroke-dasharray: var(--apend) var(--aprest);
	transition: stroke-dasharray var(--atime) ease-out;
}

#vid-progress {
	stroke: #838383; /* #428bca; */
	stroke-width: var(--sw);
}
#vid-pbar {
	stroke-width: var(--sw);
	fill: none;
	stroke: #838383;
	stroke-dasharray: var(--vpend) var(--vprest);
	transition: stroke-dasharray var(--atime) ease-out;
}

@keyframes progress {
	0% {
		stroke-dasharray: 0 1000;
	}
}

.btn {
	display: inline-block;
	text-transform: uppercase;
	text-align: center;
	font-size: 30px;
	line-height: 34px;
	text-decoration: none;
	border-bottom: none;
	cursor: pointer;
	transition: all 300ms ease-out;
	border-radius: 50%;
	position: relative;
	background: none;
	background-color: $ctDark;
	color: $cw;
	width: 66px;
	height: 66px;
	opacity: 1;

	&.animate {
		#pbar {
			transition: stroke-dasharray var(--atime) ease-out;
		}
	}

	path,
	g {
		transition: all 300ms ease-out;
	}

	svg {
		@extend %centered;

		&.progress-circle {
			width: calc(100% + 3px);
			height: calc(100% + 3px);
		}
	}

	span {
		@extend %centered;
		display: block;
		padding: 10px;
	}

	&.text-lower {
		span {
			top: 55%;
		}
	}

	&.svg-btn {
		span {
			display: none !important;
		}
	}

	&.b360 {
		@include respond-to(tablet-landscape-only) {
			svg {
				width: 35px;
				height: 12px;
			}
		}
	}

	&.text-prev,
	&.text-next {
		background: none;
		width: 100%;
		border-radius: 0px;
		position: absolute;
		left: 0;

		&:before {
			content: '';
			@extend %centered;
			font-size: 0;
			color: transparent;
			width: 0;
			height: 0;
			box-shadow: 0px 0px 40px 40px $cw;
			z-index: 1;
			opacity: 0;
			transition: opacity 0.2s ease-in;
		}

		&:hover,
		&:focus {
			background: none;
			box-shadow: none;
			&:before {
				opacity: 1;
			}
		}
	}

	&.text-prev {
		top: -10px;
	}

	&.text-next {
		bottom: -40px;
	}

	&.play-audio,
	&.play-video {
		position: absolute;
		bottom: $page-padding;
		left: $page-padding-large;

		&.playing {
			@include animation(0s, 1.5s, box-shadow);
			animation-iteration-count: infinite;
		}
	}

	&.no-sound {
		position: absolute;
		bottom: $page-padding;
		left: 180px;
		background: transparent;

		&:before {
			content: '';
			@extend %centered;
			font-size: 0;
			color: transparent;
			width: 0;
			height: 0;
			box-shadow: 0px 0px 40px 40px $ctDarker;
			z-index: 1;
		}

		@include respond-to(tablet-landscape-only) {
			svg {
				width: 24px;
				height: 24px;
			}
		}
	}

	&.show-info {
		position: absolute;
		bottom: $page-padding;
		right: $page-padding-large;

		@include respond-to(tablet-landscape-only) {
			svg {
				width: 12px;
				height: 29px;
			}
		}
	}

	&.home {
		position: absolute;
		top: 0;
		right: 22px;
		background: #343434;

		@include respond-to(tablet-landscape-only) {
			svg {
				width: 18px;
				height: 20px;
			}
		}
	}

	&.back {
		position: absolute;
		top: $page-padding;
		right: $page-padding-large;

		@include respond-to(tablet-landscape-only) {
			svg {
				width: 19px;
				height: 20px;
			}
		}
	}

	&.white {
		background: $cw;
		color: $cPrimary;
	}

	&.large {
		width: 212px;
		height: 212px;
	}

	&.center {
		@extend %centered;
	}

	&.center-top {
		@extend %centered-top;
	}

	&.na {
		background-image: none !important;
	}

	&.disabled {
		opacity: 0;
		pointer-events: none;
	}

	&:hover {
		text-decoration: none;
		background-color: $cb;
		color: $cw;
		path,
		g {
			//fill: $cRed;
		}
	}

	&:hover,
	&:focus {
		box-shadow: 0px 0 10px 2px $cw;
	}

	@include respond-to(desktop) {
		font-size: 39px;
		line-height: 47px;
		width: 81px;
		height: 81px;

		&.large {
			width: 274px;
			height: 274px;
		}
	}
}

.btns {
	padding: 20px 0px;
	.btn {
		display: inline-block;
		margin-right: 20px;
		&:last-child {
			margin-right: 0;
		}
	}
}

.close {
	@extend %close;
	&:focus {
		box-shadow: inset 0px 0 0px 30px rgba(20, 20, 20, 0.1);
	}
}
