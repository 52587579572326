nav {
	display: block;
	line-height: 1;

	ul {
		display: block;
		text-align: center;
	}
	li {
		display: inline-block;
		margin: 0px 10px;

		&.active {
			a,
			strong {
				color: $cw;
				border-bottom-color: $cw;
			}
		}
	}
	a,
	strong {
		font-weight: normal;
		display: inline-block;
		padding: 16px;
		border-bottom: 4px solid $ctWhite;
		color: $cw;
		text-transform: uppercase;

		&:hover,
		&:focus {
			color: $cw;
			border-bottom-color: $cw;
		}
	}
}
