.content-video {
	height: 100%;
}

.video-container {
	height: 100%;
}

.video-js.raf-video-skin {
	width: 100% !important;
	height: 100% !important;
	top: 0 !important;
	&:hover {
		.vjs-big-play-button {
			transition: opacity 0.2s ease-in;
			opacity: 1;
		}
	}
	.vjs-tech {
		object-fit: cover;
		// top: 50%;
		// left: 50%;
		// transform: translate(-50%,-50%);
		// position: relative;
		// max-width: none;
	}
	.vjs-big-play-button {
		@extend %overlay-icon;

		background: url(../../images/play.svg) no-repeat center;

		.vjs-icon-placeholder:before {
			display: none;
			content: '\F102';
		}
	}
	.vjs-icon-pause:before,
	.video-js
		.vjs-play-control.vjs-playing
		.vjs-icon-placeholder:before
		.vjs-icon-paused {
		&:before {
			content: '';
		}
	}
	.vjs-play-control {
		&.vjs-paused {
			.vjs-icon-placeholder {
				&:before {
					content: '';
					background: url(../../images/play.svg) no-repeat center;
				}
			}
		}
		&.vjs-playing {
			.vjs-icon-placeholder {
				&:before {
					// background: url(../../images/video/icon-pause.svg) no-repeat center;
				}
			}
		}
	}
	&.video-js.vjs-fullscreen
		.vjs-fullscreen-control
		.vjs-icon-placeholder:before {
		content: '';
	}
	.vjs-fullscreen-control {
		.vjs-icon-placeholder {
			&:before {
				content: '';
				//background: url(../../images/video/icon-videoscale.svg) no-repeat center;
			}
		}
	}
	.vjs-slider {
		height: 8px;
	}
	.vjs-progress-control:hover .vjs-progress-holder {
		font-size: 1.1em;
	}
	.vjs-play-progress {
		&:before {
			font-size: 1.75em;
			top: -0.23em;
		}
	}
	.vjs-time-tooltip {
		top: -5.5em;
	}
	.vjs-control-bar {
		background-color: transparent;
		bottom: 5px;
		left: 15px;
		right: 15px;
		width: calc(100% - 30px);
		display: none;
		@media (min-width: 600px) {
			bottom: 15px;
		}
	}
	.vjs-control {
		outline: none;
	}
	&.vjs-ended .vjs-big-play-button {
		display: block !important;
	}
	::cue {
		font-size: 15px;
		color: white;
		@media (min-width: 601px) {
			font-size: 18px;
		}
		@media (min-width: 1024px) {
			font-size: 0.5em;
		}
	}
	.vjs-subs-caps-button:focus {
		text-shadow: none;
	}
	.vjs-subs-caps-button {
		display: none;
	}
	.vjs-subs-caps-button.custom-button {
		display: block;
		&.active {
			text-shadow: 0em 0em 1em white;
		}
	}
}
