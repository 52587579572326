@font-face {
	font-family: 'Rubik';
	src: url('../../font/subset-Rubik-Italic.woff2') format('woff2'),
		url('../../font/subset-Rubik-Italic.woff') format('woff');
	font-weight: normal;
	font-style: italic;
}

@font-face {
	font-family: 'Rubik';
	src: url('../../font/subset-Rubik-Medium.woff2') format('woff2'),
		url('../../font/subset-Rubik-Medium.woff') format('woff');
	font-weight: 500;
	font-style: normal;
}

@font-face {
	font-family: 'Rubik';
	src: url('../../font/subset-Rubik-MediumItalic.woff2') format('woff2'),
		url('../../font/subset-Rubik-MediumItalic.woff') format('woff');
	font-weight: 500;
	font-style: italic;
}

@font-face {
	font-family: 'Rubik';
	src: url('../../font/subset-Rubik-Regular.woff2') format('woff2'),
		url('../../font/subset-Rubik-Regular.woff') format('woff');
	font-weight: normal;
	font-style: normal;
}

h1,
h2,
h3,
h4,
h5,
.h1,
.h2,
.h3,
.h4,
.h5,
.headline {
	margin: 0px 0px 20px 0px;
	display: block;
	position: relative;
	font-size: 20px;
	line-height: 1.2;
}

.headline {
	font-size: 30px;
	margin-bottom: 12px;
}

h1,
.h1 {
	font-size: 60px;
	line-height: 68px;
	font-weight: normal;
	margin-bottom: 10px;
}

h2,
.h2 {
	font-size: 40px;
	line-height: 46px;
	font-weight: normal;
	margin-bottom: 25px;

	&.small {
		font-size: 30px;
	}
}

h3,
.h3 {
	font-size: 26px;
	line-height: 32px;
	font-weight: normal;
	margin-bottom: 10px;
}

h4,
.h4 {
	font-size: 20px;
	font-weight: normal;
	line-height: 1.2;
	margin-bottom: 10px;
}

a {
	@extend %link;
}

p {
}

caption {
	display: block;
	font-size: 18px;
	line-height: 26px;
}

@include respond-to(desktop) {
	caption {
		font-size: 23px;
		line-height: 33px;
	}

	h1,
	.h1 {
		font-size: 78px;
		line-height: 86px;
	}

	h2,
	.h2 {
		font-size: 52px;
		line-height: 60px;

		&.small {
			font-size: 30px;
		}
	}

	h3,
	.h3 {
		font-size: 34px;
		line-height: 42px;
	}

	h4,
	.h4 {
		font-size: 26px;
	}
}

%text-content {
	@extend %cf;
	line-height: 1.43;
	font-family: $font-family;
	font-weight: 300;

	&.closer {
		padding-top: 0;
	}

	h1,
	h2,
	h3,
	h4,
	h5 {
		margin-top: 30px;
		color: $cPrimary !important;
		margin-bottom: 20px;

		&:first-child {
			margin-top: 0;
		}
	}

	h2 {
		font-size: 36px;
	}

	h3 {
		font-size: 32px;
	}

	h4,
	h5,
	h6 {
		font-size: 28px;
	}

	small {
		font-size: 12px;
	}

	ul:not(.links):not(.social):not(.stations):not(.arrow-list):not(.share-options) {
		padding: 10px 0px 20px 30px;
		list-style: none;

		li {
			list-style-type: none;
			padding: 0px 0px 0px 0px;
			position: relative;
			margin-bottom: 10px;

			&::before {
				content: '—';
				color: inherit;
				display: inline-block;
				width: 30px;
				margin-left: -30px;
			}

			ol,
			ul {
				padding-top: 10px;
			}
		}
	}

	ol {
		padding: 10px 20px 0px 30px;
		list-style: none;
		counter-reset: li;
		letter-spacing: normal;

		li {
			list-style-type: none;
			padding: 0px 0px 10px 0px;
			counter-increment: li;

			&:before {
				content: counter(li);
				display: inline-block;
				width: 30px;
				margin-left: -30px;
			}

			ol,
			ul {
				padding-top: 10px;
			}
		}
	}

	p {
		line-height: 1.5em;
		margin-bottom: 20px;

		&:last-child {
			margin-bottom: 20px;
		}
	}

	p + ul {
		margin-top: 0px;
	}

	table {
		border-collapse: collapse;
		width: 100%;
		td {
		}
	}

	blockquote {
		color: inherit;
		font-size: 35px;
		line-height: 1.14;
		margin-bottom: 20px;
		text-align: center;
	}

	p + .links,
	ul + .links,
	ol + .links,
	table + .links {
		//margin-top: -20px;
		margin-bottom: 20px;
	}
}

.text {
	@extend %text-content;
}

.text-page {
	> article {
		padding-bottom: 40px;
	}
}
