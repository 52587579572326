.content-360 {
	// detail
	width: 100%;
	height: 100%;
}

.content-360-image {
	// in slider
	position: relative;

	&:before {
		@extend %overlay-icon;
		content: '';
		background: url(../../images/360.svg) no-repeat center;
	}
}
#gallery-view {
}
