.logo {
	display: block;
	width: 81px;
	height: 51px;
	float: left;
	//background: url('../../images/rafm-logo.svg') no-repeat left center transparent;
	background-size: contain;
	border: none;
	transition: opacity 0.2s ease-in-out;
	cursor: pointer;

	span {
		display: none;
	}

	@include respond-to(mobile-large) {
	}

	@include respond-to(tablet-portrait) {
	}
}
