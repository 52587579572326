.contents {
	nav {
		margin-bottom: 60px;
		margin-top: 40px;
	}

	@include respond-to(desktop) {
		nav {
			margin-bottom: 80px;
			margin-top: 60px;
		}
	}
}

.flipMoveContainer,
.isotope-container {
	display: block;
	white-space: nowrap;
	width: 100%;
	height: calc(100vh - 300px);

	.list-item {
	}

	h3,
	h4 {
		display: block;
		width: 90%;
	}

	h3 {
		margin-bottom: 20px;
	}

	.bgi {
		margin-bottom: 20px;
		&:after {
			content: '';
			display: block;
			padding-bottom: 56.25%;
		}
	}

	@include respond-to(desktop) {
		height: calc(100vh - 400px);
	}
}

.white-text {
	color: $cw;
}

.scrollbar-track {
	display: none !important;
}

.content-list {
	@extend %cf;
	clear: both;
	overflow-y: hidden;
	overflow-x: scroll;
	white-space: nowrap;
	width: 100%;
	position: relative;

	&:before,
	&:after {
		content: '';
		z-index: 3;
		position: absolute;
		left: 0px;
		top: 0;
		width: 0px;
		height: 100%;
		background-color: $ctDark;
		box-shadow: 30px -10px 60px 51px $ctDark;
		opacity: 0;
		transition: opacity 0.2s ease-in;
	}
	&:after {
		left: auto;
		right: 0px;
		box-shadow: -40px -10px 68px 51px $ctDark;
	}

	&.has-prev {
		&:before {
			opacity: 1;
		}
	}

	&.has-next {
		&:after {
			opacity: 1;
		}
	}
}

.list-item {
	display: inline-block;
	vertical-align: top;
	white-space: normal;
	width: 33vw;
	margin-right: 80px;

	a {
		color: $cw;
		display: block;
		text-decoration: none;
		border-bottom: none;

		&.image i:before {
			background-image: url('../../images/image.svg');
			background-size: 29px 22px;
		}
		&.series i:before {
			background-image: url('../../images/images.svg');
			background-size: 34px 28px;
		}
		&.sound i:before {
			background-image: url('../../images/audio.svg');
			background-size: 29px 33px;
		}
		&.c360 i:before {
			background-image: url('../../images/360.svg');
			background-size: 45px 15px;
		}
		&.play i:before {
			background-image: url('../../images/play.svg');
			background-size: 25px 33px;
			margin-left: 3px;
		}
		&.concept i:before {
			background-image: url('../../images/concept.svg');
			background-size: 30px 42px;
		}
	}

	i {
		@extend %centered;
		display: block;
		width: 85px;
		height: 85px;
		background: $cw;
		transition: all 300ms ease-out;
		border-radius: 50%;

		&:before {
			content: '';
			display: block;
			width: 60px;
			height: 60px;
			background-repeat: no-repeat;
			background-position: center center;

			@extend %centered;
		}
	}

	&.visited {
		h3,
		h4 {
			opacity: 0.4;
		}

		.bgi {
			&:after {
				background: rgba(0, 0, 0, 0.6);
			}
		}

		i {
			background: #343434;

			&:before {
				background-image: url('../../images/check.svg') !important;
				background-size: 27px 19px !important;
			}
		}
	}

	@include respond-to(tablet-landscape-only) {
		a {
			&.image i:before {
				background-size: 21px 16px;
			}
			&.series i:before {
				background-size: 25px 20px;
			}
			&.sound i:before {
				background-size: 14px 22px;
			}
			&.c360 i:before {
				background-size: 27px 9px;
			}
			&.play i:before {
				background-size: 14px 17px;
			}
			&.concept i:before {
				background-size: 30px 42px;
			}
		}

		i {
			width: 65px;
			height: 65px;
		}
	}
}

.slider-prev,
.slider-next {
	position: absolute;
	bottom: 0;
}

.slider-prev {
	left: 22px;
}

.slider-next {
	right: 22px;
}
