.homepage {
	nav {
		position: absolute;
		bottom: 0px;
		left: 0;
		width: 100%;
	}

	.headline,
	nav,
	.explore,
	.intro {
		opacity: 0;
		pointer-events: none;
	}

	.intro {
		.btn {
			opacity: 0;
		}
	}

	.start {
		opacity: 0;
		pointer-events: all;
		@include animation(1s, 0.5s, fadeIn);
	}

	&.not-sleeping {
		nav {
			pointer-events: all;
			@include animation(2.5s, 1.5s, fadeInUp);
		}

		p {
			opacity: 0;
			@include animation(1s, 0.5s, fadeInUp);
		}

		.headline {
			opacity: 0;
			@include animation(0s, 0.5s, fadeInUp);
		}

		.intro {
			opacity: 1;
			pointer-events: all;
			h1 {
				opacity: 0;
				@include animation(0.5s, 0.5s, fadeInUp);
			}
			.btn {
				opacity: 0;
				@include animation(1.5s, 0.5s, fadeInUp);
			}
		}

		.explore {
			pointer-events: all;
			@include animation(0.5s, 2.5s, fadeIn);
		}

		.start {
			pointer-events: none;
			@include animation(0s, 0.2s, fadeOut);
		}
	}
}

.bg-video {
	@extend %fullscreen;
	object-fit: cover;
	position: absolute;
	top: 0;
	left: 0;
	z-index: 1;
}

.overlay {
	@extend %fullscreen;
	position: absolute;
	top: 0;
	left: 0;
	z-index: 2;
	background: #000;
	opacity: 0.4;
}

.intro {
	float: left;
	width: 80%;
	color: $cw;

	p {
		max-width: 320px;
	}

	svg {
		width: 35px;
		height: 12px;
	}

	@include respond-to(desktop) {
		p {
			max-width: 514px;
		}
	}
}
