main {
	&.content {
		padding: 0px;

		.btn {
			z-index: 9;
		}
	}
}

.slick-next,
.slick-prev {
	z-index: 9;
	width: 80px;
	height: 80px;
	background: none;
	transition: opacity 0.3s ease-in;
	opacity: 1;

	&:before {
		content: '';
		@extend %centered;
		font-size: 0;
		color: transparent;
		width: 0;
		height: 0;
		box-shadow: 0px 0px 40px 40px $ctDark;
		z-index: 1;
	}

	&:after {
		content: '';
		@extend %centered;
		font-size: 0;
		z-index: 2;
		color: transparent;
		width: 40px;
		height: 40px;
	}

	&.slick-disabled {
		opacity: 0;
	}

	&:hover,
	&:focus {
		&:before {
			box-shadow: 0px 0px 40px 40px $ctDarker;
		}
	}
}

.slick-next {
	right: $page-padding-large;
	&:after {
		background: url('../../images/arrow-right-white.svg') no-repeat center
			center;
	}

	&:hover,
	&:focus {
		&:after {
			background: url('../../images/arrow-right-white.svg') no-repeat
				center;
		}
	}
}

.slick-prev {
	left: $page-padding-large;
	&:after {
		background: url('../../images/arrow-left-white.svg') no-repeat center
			center;
	}

	&:hover,
	&:focus {
		&:after {
			background: url('../../images/arrow-left-white.svg') no-repeat
				center center;
		}
	}
}

.slick-list {
}

.slick-dots {
}

.gallery-container {
	@extend %cf;
	@extend %fullscreen;
	z-index: 1;

	.bgi {
		height: 100%;
		&.portrait {
			background-position: center top;
		}
		&.landscape {
			background-position: center center;
		}
	}

	&.no-slides {
		.zoom {
			top: 0;
			margin-bottom: 0;
			margin-top: 10px;
		}
	}

	&.detail-open {
		z-index: 999;

		.slick-next,
		.slick-prev {
			opacity: 0 !important;
			pointer-events: none;
		}
	}

	&.inactive {
		.slick-next,
		.slick-prev {
			transition: opacity 1s ease-in;
			opacity: 0 !important;
			pointer-events: none;
		}

		+ .btn.back {
			transition: opacity 1s ease-in;
			opacity: 0 !important;
			pointer-events: none;
		}

		.btn.show-info {
			transition: opacity 1s ease-in;
			opacity: 0 !important;
			pointer-events: none;
		}
	}
}

.activity {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	background: transparent;
}

.video-container {
	@extend %landscape;
	position: relative;

	video {
		object-fit: cover;
	}

	.video-js {
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
	}
}

.slick-track {
	height: 100%;
}

.slick-slider,
.slick-list {
	width: auto !important;
	height: 100%;
}

.slick-slide {
	height: 100%;
	position: relative;
	> div {
		height: 100%;
	}
}

.bottom-info,
.slick-dots {
	position: absolute;
	bottom: 0;
	width: calc(100% - 500px);
	left: 50%;
	transform: translate(-50%, 0%);
	text-align: center;
}

.slick-dots {
	display: flex !important;
	flex-direction: row;
	align-items: stretch;
	height: 20px;
	z-index: 99;

	> li {
		height: 20px;
		flex: 1;
		border-bottom: 4px solid #f7f7ef;
		width: auto;
		margin: 0px 4px 0px 0px;
		transition: border-color 0.2s ease-in;
		span {
			display: block;
			position: absolute;
			top: 0;
			left: 0;
			width: 100%;
			height: 100%;
			font-size: 0px;
			color: transparent;
		}

		&:last-child {
			margin: 0;
		}

		&.slick-active {
			~ li {
				border-bottom: 4px solid #b3b3b3;
			}
		}
	}
}

.media-wrap {
	height: 100%;
}

.bottom-info {
	padding: 20px 30px 30px;
	background: rgba(0, 0, 0, 0.5);
	color: $cw;
}

.textOverflow {
	/*
    -webkit-box-orient: vertical; 
    position: relative;
    display: block;
    display: -webkit-box;
    max-width: 100%;
    height: 42.2px;
    -webkit-line-clamp: 2;
    overflow: hidden;
    -o-text-overflow: ellipsis;
    text-overflow: ellipsis;
    */
}

// overlay
.overlay-screen {
	@extend %modal;
}

// info

.text-contents {
	overflow-y: hidden;
	overflow-x: scroll;
	width: 100%;
	height: 100%;
	position: relative;

	&:before,
	&:after {
		content: '';
		z-index: 3;
		position: absolute;
		left: 0px;
		top: 0;
		width: 100%;
		height: 20px;
		background: linear-gradient(
			to bottom,
			$cModalBg 0%,
			rgba(255, 255, 255, 0) 100%
		);
		opacity: 0;
		transition: opacity 0.2s ease-in;
	}
	&:after {
		top: auto;
		bottom: 0px;
		background: linear-gradient(
			to bottom,
			rgba(255, 255, 255, 0) 0%,
			$cModalBg 100%
		);
	}

	&.has-prev {
		&:before {
			opacity: 1;
		}
	}

	&.has-next {
		&:after {
			opacity: 1;
		}
	}
}

.full-info {
	background-color: transparent;
	transition: background-color 0.2s ease-in;
	height: 100%;
	padding: $page-padding-large;
	position: relative;
	color: $cPrimary;

	.media-col {
		width: 40%;
		height: 100%;
		float: left;
	}

	.detail-media-wrap {
		height: 100%;
		position: relative;
	}

	.text-col {
		width: 40%;
		height: 100%;
		padding: 70px 0px 40px 0px;
		float: right;
		position: relative;
	}

	&.hidden {
		padding: 0;
		.media-col {
			width: 100%;
			float: none;
		}

		.text-col {
			display: none;
		}

		.btn {
			display: none;
		}
	}

	&.visible {
		background-color: $cModalBg;
		z-index: 99;

		.media-col {
		}

		caption {
			margin-top: 20px;
		}

		.detail-media-wrap {
			@extend %square;
			width: 100%;
			height: auto;

			.video-container,
			.video-js.raf-video-skin,
			.bgi,
			> div {
				position: absolute;
				top: 0;
				left: 0;
				width: 100%;
				height: 100%;
			}
		}

		.bgi {
			height: auto;
			background: none !important;
			text-align: center;

			&:not(.landscape) {
				@extend %square;

				> img {
					position: absolute;
					@extend %centered;
				}
			}

			> img {
				display: inline-block !important;
				width: auto;
				max-height: 100%;
			}
		}
	}
}
