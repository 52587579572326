// Variables ===================================================================================== /

$cb: hsl(0, 0%, 0%);
$cw: hsl(0, 0%, 100%);
$cBg: #000000;
$cPrimary: #000000;
$cGrey: #d4d4d4;
$cRedBg: #fdf3f3;
$cRedBgHover: #ffffff;
$cRed: #d6002a;
$cRedLight: #e64444;
$cModalBg: rgba(227, 225, 222, 1);

$cLine: #d8d8d8;
$ctDark: rgba(0, 0, 0, 0.6);
$ctDarker: rgba(0, 0, 0, 0.8);

$ctWhite: rgba(255, 255, 255, 0.3);

$cLink: #252525;
$cLinkHover: #d6002a;

$cLinkRed: #d6002a;
$cLinkRedHover: #9d1b1b;

$cLinkBlue: #2023ac;
$cLinkBlueHover: #191b7a;

$selectedOption: #e9f6ff;
$focusColor: #afcfe8;
$cCorrect: #407e1f;
$cIncorrect: #d6002a;

$font-family: 'Rubik', sans-serif;

$font-size: 20px;
$vertical-measure: 1em;
$line-height: 1.5;

$max-width: 2700px; //1359px; // set page max-width
$full-width: 1460px; // set page max-width

$gutter-width: 20px; // set gutter width
$gutter-half-width: 10px; // set gutter width
$page-padding: 38px;
$page-padding-large: 60px;
$content-top-padding: 64px;

$minw-mp: 320px;
$minw-mxl: 375px;
$minw-ml: 480px;
$minw-tp: 750px;
$minw-tl: 1024px;
$minw-d: 1200px;
$minw-dxl: 1600px;
